<template>
    <div class="son-page">
        <div class="inside-page">
            <Form :model="formItem" :label-width="140" style="width: 500px;margin-top: 20px">
                <FormItem label="门店名称：">
                    <Input v-model="formItem.store_name" placeholder="请输入门店名称"></Input>
                </FormItem>
                <FormItem label="门店状态：">
                    <RadioGroup v-model="formItem.status">
                        <Radio :label="1">开业</Radio>
                        <Radio :label="0">停业</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem label="支持模式：">
                    <CheckboxGroup v-model="formItem.mode">
                        <Checkbox label="1">堂食</Checkbox>
                        <Checkbox label="2">外卖</Checkbox>
                        <Checkbox label="3">自取</Checkbox>
                    </CheckboxGroup>
                </FormItem>
                <FormItem label="支付方式：">
                    <CheckboxGroup v-model="formItem.pay_mode">
                        <Checkbox :label="1" v-if="formItem.support_pay_mode.indexOf(1) != '-1'">微信支付</Checkbox>
                        <Checkbox :label="2" v-if="formItem.support_pay_mode.indexOf(2) != '-1'">支付宝支付</Checkbox>
                        <Checkbox :label="3" v-if="formItem.support_pay_mode.indexOf(3) != '-1'">余额支付</Checkbox>
                    </CheckboxGroup>
                </FormItem>
                <!-- <FormItem label="充值按钮：">
          <i-switch size="large" v-model="formItem.is_recharge" :true-value="1" :false-value="0">
              <span slot="open">开启</span>
              <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <FormItem label="预留手机：">
          <RadioGroup v-model="formItem.stay_phone">
            <Radio :label="1">开启</Radio>
            <Radio :label="0">关闭</Radio>
          </RadioGroup>
        </FormItem> -->
                <FormItem label="营业时间：">
                    <Button @click="setupBtn">设置</Button>
                </FormItem>
                <FormItem label="送达时间设置：">
                    <Button @click="serviceBtn">设置</Button>
                </FormItem>
                <!-- <FormItem label="门店logo：">
          <div class="pic_box">
            <div>
              <Upload
                  ref="upload"
                  :show-upload-list="false"
                  :format="['jpg','jpeg','png']"
                  :max-size="2048"
                  :headers="header"
                  :on-format-error="handleFormatError"
                  :on-success="uploadImgSuccess"
                  :on-progress="handleProgress"
                  multiple
                  type="drag"
                  :action="uploads"
                  style="display: inline-block;width:100px;">
                <div style="width: 100px;height:100px;line-height: 100px;">
                  <Icon type="md-add" size="35" />
                </div>
              </Upload>
            </div>
            <div v-if="pic" class="pic"><img :src="pic" alt=""></div>
          </div>
        </FormItem> -->
                <FormItem label="联系人：">
                    <Input v-model="formItem.contact" placeholder="请再次输入联系人"></Input>
                </FormItem>
                <FormItem label="联系电话：">
                    <Input v-model="formItem.phone" placeholder="请再次输入联系电话"></Input>
                </FormItem>
                <FormItem label="详细地址：">
                    <div style="display: flex;">
                        <Input v-model="formItem.address" placeholder="请再次输入详细地址" style="width: 280px"></Input>
                        <div @click="searchBtn" class="btn-search">搜索</div>
                    </div>
                </FormItem>
                <FormItem label="经纬度：">
                    <Input disabled v-model="formItem.title" :placeholder="`${formItem.lng} , ${formItem.lat}`"></Input>
                </FormItem>
                <FormItem label="地图定位：">
                    <baidu-map :center="{ lng: formItem.lng, lat: formItem.lat }" :zoom="18" @ready="handler"
                        style="height:300px;width: 700px" @click="getClickInfo" :scroll-wheel-zoom='false'>
                        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                        <bm-marker :position="{ lng: formItem.lng, lat: formItem.lat }" :dragging="false"
                            animation=""></bm-marker>
                    </baidu-map>
                </FormItem>
                <FormItem label="门店介绍：">
                    <textarea v-model="formItem.desc" class="textar" name="" id="" cols="30" rows="10"></textarea>
                </FormItem>
            </Form>
        </div>
        <div class="bottom">
            <div @click="addBtn" class="btn">保存</div>
        </div>
        <Modal v-model="setupModal" title="营业时间" @on-cancel="cancel" :mask-closable="false" width="700"
            :styles="{ top: '100px' }">
            <Form style="height:500px;overflow:auto" label-position="top">
                <div style="font-weight:600;font-size:18px;margin-bottom:15px">外卖/自取营业时间</div>
                <FormItem label="星期选择">
                    <CheckboxGroup class="fenbu" v-model="formItem.week">
                        <Checkbox label="1">星期一</Checkbox>
                        <Checkbox label="2">星期二</Checkbox>
                        <Checkbox label="3">星期三</Checkbox>
                        <Checkbox label="4">星期四</Checkbox>
                        <Checkbox label="5">星期五</Checkbox>
                        <Checkbox label="6">星期六</Checkbox>
                        <Checkbox label="0">星期天</Checkbox>
                    </CheckboxGroup>
                </FormItem>
                <FormItem label="时间段选择">
                    <div style="margin-bottom:10px;display: flex;align-items: center;"
                        v-for="(item, index) of formItem.business_hours" :key="index">
                        <TimePicker v-model="item.time" ref="element" @on-change="time($event, index)" format="HH:mm"
                            type="timerange" placement="bottom-end" placeholder="点击选择时间" style="width: 300px"></TimePicker>
                        <Input v-model="item.value" style="width:100px;margin:0 5px"></Input>
                        <Tooltip style="cursor: pointer;" max-width="500" content="客户在此时间段可下单次数，0为不限制" placement="top">
                            <Icon size="20" type="ios-help-circle" />
                        </Tooltip>
                        <Icon @click="closeBtn(index)" style="color:red;font-size:20px;margin-left:10px"
                            type="ios-remove-circle-outline" />
                    </div>
                    <div>
                        <Button @click="addTime" style="width:60px;text-align:cemter;" icon="md-add"></Button><span
                            style="margin-left:10px;color:red">最多3个时间段</span>
                    </div>
                </FormItem>
                <div style="width:100%;height:1px;border-bottom:1px solid #eee;margin-bottom: 20px"></div>
                <div style="font-weight:600;font-size:18px;margin-bottom:15px">堂食营业时间</div>
                <FormItem label="星期选择">
                    <CheckboxGroup class="fenbu" v-model="formItem.t_week">
                        <Checkbox label="1">星期一</Checkbox>
                        <Checkbox label="2">星期二</Checkbox>
                        <Checkbox label="3">星期三</Checkbox>
                        <Checkbox label="4">星期四</Checkbox>
                        <Checkbox label="5">星期五</Checkbox>
                        <Checkbox label="6">星期六</Checkbox>
                        <Checkbox label="0">星期天</Checkbox>
                    </CheckboxGroup>
                </FormItem>
                <FormItem label="时间段选择">
                    <div style="margin-bottom:10px;display: flex;align-items: center;"
                        v-for="(item, index) of formItem.t_business_hours" :key="index">
                        <TimePicker v-model="item.time" ref="element" @on-change="time1($event, index)" format="HH:mm"
                            type="timerange" placement="bottom-end" placeholder="点击选择时间" style="width: 300px"></TimePicker>
                        <Input v-model="item.value" style="width:100px;margin:0 5px"></Input>
                        <Tooltip style="cursor: pointer;" max-width="500" content="客户在此时间段可下单次数，0为不限制" placement="top">
                            <Icon size="20" type="ios-help-circle" />
                        </Tooltip>
                        <Icon @click="closeBtn1(index)" style="color:red;font-size:20px;margin-left:10px"
                            type="ios-remove-circle-outline" />
                    </div>
                    <div>
                        <Button @click="addTime1" style="width:60px;text-align:cemter;" icon="md-add"></Button><span
                            style="margin-left:10px;color:red">最多3个时间段</span>
                    </div>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="cancel">取消</Button>
                <Button style="background:#FF761D;border-color:#FF761D" @click="addsetupBtn" type="primary">保存</Button>
            </div>
        </Modal>

        <Modal v-model="serviceModal" title="送达时间" @on-cancel="cancelBtn" :mask-closable="false" width="600"
            :styles="{ top: '100px' }">
            <div class="title_text">自取时间设置</div>
            <Form label-position="left" :label-width="110">
                <FormItem label="自取时间选择：">
                    <i-switch size="large" v-model="formItem.z_time_status" :true-value="1" :false-value="0">
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </FormItem>
                <span v-if="formItem.z_time_status == 1">
                    <FormItem label="预约天数：">
                        <Input v-model="formItem.z_days" placeholder="请输入自取天数（1~5）" style="width:300px"
                            type="number"></Input>
                        天
                        <Tooltip content="未来几天内可进行预约" placement="top">
                            <Icon style="margin-left:10px" size="20" type="ios-help-circle" />
                        </Tooltip>
                    </FormItem>
                    <FormItem label="延后时间：">
                        <Input v-model="formItem.z_first_minute" placeholder="请输入延后时间（分钟）" style="width:300px"
                            type="number"></Input>
                        分钟
                        <Tooltip max-width="300" content="下单时，最早可预约时间，为当前时间后延多少分钟" placement="left">
                            <Icon style="margin-left:10px" size="20" type="ios-help-circle" />
                        </Tooltip>
                    </FormItem>
                    <FormItem label="间隔时间：">
                        <Input v-model="formItem.z_interval_minute" placeholder="请输入间隔时间（分钟）" style="width:300px"
                            type="number"></Input>
                        分钟
                        <Tooltip content="一天内多次预约的时间间隔" placement="top">
                            <Icon style="margin-left:10px" size="20" type="ios-help-circle" />
                        </Tooltip>
                    </FormItem>
                    <FormItem label="非营业预约：">
                        <i-switch size="large" v-model="formItem.z_appointment" :true-value="1" :false-value="0">
                            <span slot="open">开启</span>
                            <span slot="close">关闭</span>
                        </i-switch>
                    </FormItem>
                </span>
            </Form>
            <div class="title_text">外卖时间设置</div>
            <Form label-position="left" :label-width="110">
                <FormItem label="外卖时间选择：">
                    <i-switch size="large" v-model="formItem.w_time_status" :true-value="1" :false-value="0">
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </FormItem>
                <span v-if="formItem.w_time_status == 1">
                    <FormItem label="预约天数：">
                        <Input v-model="formItem.w_days" placeholder="请输入自取天数（1~5）" style="width:300px"
                            type="number"></Input>
                        天
                        <Tooltip content="未来几天内可进行预约" placement="top">
                            <Icon style="margin-left:10px" size="20" type="ios-help-circle" />
                        </Tooltip>
                    </FormItem>
                    <FormItem label="延后时间：">
                        <Input v-model="formItem.w_first_minute" placeholder="请输入延后时间（分钟）" style="width:300px"
                            type="number"></Input>
                        分钟
                        <Tooltip max-width="300" content="下单时，最早可预约时间，为当前时间后延多少分钟" placement="left">
                            <Icon style="margin-left:10px" size="20" type="ios-help-circle" />
                        </Tooltip>
                    </FormItem>
                    <FormItem label="间隔时间：">
                        <Input v-model="formItem.w_interval_minute" placeholder="请输入间隔时间（分钟）" style="width:300px"
                            type="number"></Input>
                        分钟
                        <Tooltip content="一天内多次预约的时间间隔" placement="top">
                            <Icon style="margin-left:10px" size="20" type="ios-help-circle" />
                        </Tooltip>
                    </FormItem>
                    <FormItem label="非营业预约：">
                        <i-switch size="large" v-model="formItem.w_appointment" :true-value="1" :false-value="0">
                            <span slot="open">开启</span>
                            <span slot="close">关闭</span>
                        </i-switch>
                    </FormItem>
                </span>
            </Form>
            <div slot="footer">
                <Button @click="cancelBtn">取消</Button>
                <Button style="background:#FF761D;border-color:#FF761D" @click="cancelBtn" type="primary">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { getStoreData, saveData, getLocation, setStoreData } from '@/api/index'
import config from '@/config';
import { getCookies } from '@/utils/helper'
export default {
    name: "business",
    data() {
        return {
            header: {
                'author-token': getCookies('canyin_token')
            },
            uploads: config.apiUrl + '/storeapi/data/uploads',
            setupModal: false,
            serviceModal: false,
            zoom: 16,
            pic: '',
            formItem: {
                status: 1,
                mode: [],
                support_pay_mode: [],
                pay_mode: [],
                is_recharge: 1,
                store_name: '',
                store_logo: '',
                contact: '',
                z_days: '',
                z_first_minute: '',
                z_interval_minute: '',
                z_time_status: 0,
                stay_phone: 0,
                w_days: '',
                w_first_minute: '',
                w_interval_minute: '',
                w_appointment: 0,
                z_appointment: 0,
                w_time_status: 0,
                phone: '',
                address: '',
                lng: '117.233639',
                lat: '31.784813',
                week: [],
                business_hours: [
                    { start_time: '', end_time: '', time: [], value: 0 }
                ],
                t_week: [],
                t_business_hours: [
                    { start_time: '', end_time: '', time: [], value: 0 }
                ],
                desc: ''
            },
        }
    },
    created() {
        this.getinfo()
    },
    methods: {
        getinfo() {
            getStoreData().then(res => {
                this.formItem.status = res.data.status
                this.formItem.mode = res.data.mode
                // this.formItem.store_name = this.$store.state.userInfo.store_name
                this.formItem.store_name = res.data.store_name
                this.formItem.store_logo = res.data.store_logo
                this.pic = res.data.site_logo_domain
                this.formItem.contact = res.data.contact
                this.formItem.phone = res.data.phone
                this.formItem.address = res.data.address
                // let lang = this.txMapToBdMap(res.data.lng,res.data.lat)
                // console.log(lang);
                this.formItem.lng = res.data.lng
                this.formItem.lat = res.data.lat
                this.formItem.stay_phone = res.data.stay_phone
                this.formItem.week = res.data.week
                this.formItem.business_hours = res.data.business_hours
                this.formItem.t_week = res.data.t_week
                this.formItem.t_business_hours = res.data.t_business_hours
                this.formItem.desc = res.data.desc
                this.formItem.pay_mode = res.data.pay_mode
                this.formItem.support_pay_mode = res.data.support_pay_mode
                this.formItem.is_recharge = res.data.is_recharge
                this.formItem.z_days = res.data.z_days
                this.formItem.z_first_minute = res.data.z_first_minute
                this.formItem.z_interval_minute = res.data.z_interval_minute
                this.formItem.z_time_status = res.data.z_time_status
                this.formItem.w_days = res.data.w_days
                this.formItem.w_first_minute = res.data.w_first_minute
                this.formItem.w_interval_minute = res.data.w_interval_minute
                this.formItem.w_appointment = res.data.w_appointment
                this.formItem.z_appointment = res.data.z_appointment
                this.formItem.w_time_status = res.data.w_time_status
            })
        },
        txMapToBdMap(lng, lat) {
            let x_pi = (3.14159265358979324 * 3000.0) / 180.0;
            let x = lng;
            let y = lat;
            let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
            let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
            let lngs = z * Math.cos(theta) + 0.0065;
            let lats = z * Math.sin(theta) + 0.006;

            return {
                lng: lngs,
                lat: lats,
            };
        },
        handler({ BMap, map }) {
            this.BMap = BMap
            this.map = map
        },
        closeBtn(e) {
            this.formItem.business_hours.splice(e, 1)
        },
        closeBtn1(e) {
            this.formItem.t_business_hours.splice(e, 1)
        },
        cancel() {
            // this.formItem.week = []
            // this.formItem.business_hours = [{start_time:'',end_time:'',time:[]}]
            // if (this.$refs.element[0]) {
            //   this.$refs.element[0].handleClear()
            // }else if (this.$refs.element[1]) {
            //   this.$refs.element[1].handleClear()
            // }else if (this.$refs.element[2]) {
            //   this.$refs.element[2].handleClear()
            // }
            this.setupModal = false
        },
        cancelBtn() {
            this.serviceModal = false
        },
        // 保存按钮
        addBtn() {
            setStoreData(this.formItem).then(res => {
                this.$Message.success(res.msg)
            }).catch(err => {
                this.$Message.error(err.msg)
            })
            // saveData(this.formItem).then(res=>{
            //   this.$Message.success(res.msg)
            // }).catch(err=>{
            //   this.$Message.error(err.msg)
            // })
        },
        // 设置营业时间弹框
        setupBtn() {
            this.setupModal = true
        },
        serviceBtn() {
            this.serviceModal = true
        },
        searchBtn() {
            getLocation({ address: this.formItem.address }).then(res => {
                this.formItem.lat = res.data.lat
                this.formItem.lng = res.data.lng
            })
        },
        time(e, index) {
            if (index == 0) {
                this.formItem.business_hours[0].start_time = e[0]
                this.formItem.business_hours[0].end_time = e[1]
            } else if (index == 1) {
                this.formItem.business_hours[1].start_time = e[0]
                this.formItem.business_hours[1].end_time = e[1]
            } else if (index == 2) {
                this.formItem.business_hours[2].start_time = e[0]
                this.formItem.business_hours[2].end_time = e[1]
            }
        },
        time1(e, index) {
            if (index == 0) {
                this.formItem.t_business_hours[0].start_time = e[0]
                this.formItem.t_business_hours[0].end_time = e[1]
            } else if (index == 1) {
                this.formItem.t_business_hours[1].start_time = e[0]
                this.formItem.t_business_hours[1].end_time = e[1]
            } else if (index == 2) {
                this.formItem.t_business_hours[2].start_time = e[0]
                this.formItem.t_business_hours[2].end_time = e[1]
            }
        },
        // 添加时间段
        addTime() {
            if (this.formItem.business_hours.length < 3) {
                this.formItem.business_hours.push({ start_time: '', end_time: '', value: 0 })
            } else {
                this.$Message.warning('最多3个时间段')
            }
        },
        // 添加时间段
        addTime1() {
            if (this.formItem.t_business_hours.length < 3) {
                this.formItem.t_business_hours.push({ start_time: '', end_time: '', value: 0 })
            } else {
                this.$Message.warning('最多3个时间段')
            }
        },
        // 设置营业时间按钮
        addsetupBtn() {
            this.setupModal = false
        },
        getClickInfo(e) {
            this.formItem.lng = e.point.lng
            this.formItem.lat = e.point.lat
        },
        //文件上传类型错误
        handleFormatError() {
            this.$Message.warning('暂不支持上传此格式');
        },
        //添加图片上传图片成功
        uploadImgSuccess(e) {
            this.pic = e.data.path
            this.formItem.store_logo = e.data.src
            this.$Message.destroy()
            this.$Message.success(e.msg);
        },
        handleProgress() {
            const msg = this.$Message.loading({
                content: '上传中...',
                duration: 0
            });
            setTimeout(msg, 3000);
        },
    }
}
</script>

<style scoped>
.son-page {
    padding: 20px;
    background: #fff;
    box-sizing: border-box;
    height: 88vh;
    position: relative;
}

.bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 2px solid #F6F6F6;
    padding: 20px 60px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.btn {
    width: 96px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FF761D;
    color: #fff;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
}

.btn:hover {
    opacity: 0.6;
}

.inside-page {
    height: 77vh;
    overflow: auto;
}

.pic_box {
    display: flex;
}

.pic {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 20px;
    border: 1px solid #eee;
}

.pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.btn-search {
    width: 80px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff5c11;
    border-radius: 4px;
    color: white;
    margin-left: 16px;
    cursor: pointer;
}

.fenbu {
    display: flex;
    justify-content: space-between;
}

.textar {
    max-width: 360px;
    min-width: 360px;
    max-height: 100px;
    min-height: 100px;
    outline: #57A3F3;
    border: 1px solid #DCDEE2;
}

.title_text {
    font-size: 16px;
    font-weight: 600;
}
</style>
<style>
.anchorBL {
    display: none;
}

.ivu-radio-checked .ivu-radio-inner {
    border-color: #FF761D;
}

.ivu-radio-inner:after {
    background-color: #FF761D;
}

.ivu-checkbox-checked .ivu-checkbox-inner {
    border-color: #FF761D;
    background-color: #FF761D;
}</style>
